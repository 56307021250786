import React from "react"
import ProfileImage from "../profileImage"
import styles from "./helloWidget.module.css"

const HelloWidget = () => (
  <div className={styles.container}>
    <ProfileImage className={styles.profileImage} />
    <p className={styles.blurb}>
      Hola! I'm Michelle. I love film photography, curly hair, and making home
      videos. You can usually find me eating dark chocolate and shooting on my
      Canon Elan 7 or Rolleiflex Automat 3.5.{" "}
    </p>
  </div>
)

export default HelloWidget
