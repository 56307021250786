import React from "react"
import { Link } from "gatsby"

import HighlightText from "../components/highlightText"
import Layout from "../components/layout"
import PostFeed from "../components/postFeed"
import HelloWidget from "../components/helloWidget"
import SEO from "../components/seo"
import styles from "./index.module.css"

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <div className={styles.headerContainer}>
        <div>
          <Link to="/blog">
            <h1 className={styles.bigLink}>
              <HighlightText showOnMobile>Blog</HighlightText>
            </h1>
          </Link>
          <a
            href="https://www.youtube.com/playlist?list=PLCdVM0pRF7mLR8xquJQWsyOiYUndYre0t"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className={styles.bigLink}>
              <HighlightText showOnMobile>Video</HighlightText>
            </h1>
          </a>
          <a
            href="https://michellewatkins.myportfolio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className={styles.bigLink}>
              <HighlightText showOnMobile>Work</HighlightText>
            </h1>
          </a>
        </div>
        <HelloWidget />
      </div>
      <PostFeed />
    </Layout>
  )
}
